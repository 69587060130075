.job__details__container {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  font-size: 14px;
}

.job__details__container:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.15);
}

.details__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.company__logo {
  flex: 0 0 auto;
}

.company__logo img {
  width: 120px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.header__text {
  flex: 1;
  text-align: right;
  padding-left: 20px;
}

.header-text h2 {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  color: #333;
}

.company__info {
  color: #666;
  margin-top: 10px;
  font-size: 14px;
}

.company__name {
  font-weight: bold;
  color: #333;
  margin-right: 10px;
}

.details__location {
  color: #999;
}

.job__description {
  margin-bottom: 20px;
  line-height: 1.6;
  color: #444;
  font-size: 14px;
}

.additional__details {
  margin-bottom: 20px;
}

.additional-details p {
  margin-bottom: 10px;
  color: #555;
  font-size: 16px;
}

.skills {
  margin-bottom: 20px;
}

.skills p {
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.skills ul {
  list-style-type: none;
  padding: 0;
}

.skills ul li {
  background-color: #f4f4f4;
  margin-bottom: 5px;
  padding: 10px;
  border-radius: 5px;
  color: #666;
  position: relative;
  padding-left: 30px;
}

.skills ul li::before {
  content: "•";
  color: #007bff;
  font-size: 20px;
  position: absolute;
  left: 10px;
  top: 10px;
}

.apply-button {
  text-align: center;
  margin-top: 20px;
}

.apply-button a {
  display: inline-block;
  padding: 12px 25px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-size: 16px;
  font-weight: bold;
}

.apply-button a:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .details-header {
    flex-direction: column;
    align-items: center;
  }

  .header-text {
    text-align: center;
    margin-top: 10px;
    padding-left: 0;
  }

  .company-logo img {
    width: 100px;
  }

  .job-details-container {
    padding: 15px;
  }
}
