.search {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
.search__option__left {
  flex: 0.2;
}

.search__option__middle {
  display: flex;
  flex: 0.6;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  background-color: #eef3f8;
  border-radius: 25px;
  padding: 10px 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 600px;
}

.search__option__right {
  flex: 0.2;
}

.search__option__middle > input {
  border: none;
  width: 100%;
  padding: 10px;
  outline-width: 0;
  font-size: medium;
  background-color: transparent;
}

.search__icon {
  color: #888;
  margin-right: 10px;
  transition: color 0.3s;
}

.search__input {
  border: none;
  outline: none;
  background: none;
  flex-grow: 1;
  font-size: 16px;
}

.search__input::placeholder {
  color: #aaa;
}

.search__option__middle:hover {
  background-color: #e0e0e0;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.search__input:focus {
  background-color: #fff;
}

@media (max-width: 768px) {
}
