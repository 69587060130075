.job__card {
  width: 23%;
  margin-bottom: 20px;
  padding: 5px;
  border: 0.1px solid #e7e4e4;
  border-radius: 9px;
  height: 270px;
  background-color: #eef3f8;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.job__card:hover {
  background-color: whitesmoke;
  transform: translateY(-2px);
}

.job-card img {
  max-width: 100%;
}

.logo-container {
  width: 70px;
  height: 70px;
  overflow: hidden;
  border-radius: 10%;
  margin: 5px auto;
}

.card__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  border-radius: 8px;
  background-color: #f0f3f7;
}

.card__content .logo-container {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 10px;
}

.card__content p {
  margin: 7px 0;
}

.card__content .title {
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
}

.card__content .location__availability {
  font-size: 14px;
}

.time__ago {
  color: #999;
  font-size: 12px;
}

.card__content img {
  width: 100%;
  height: auto;
}

.company__size_info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.company__size_info .company__info__size__logo {
  margin-left: 15px;
  color: gray;
  width: 20px;
  height: 20px;
}
.company__size_info .company__info__size__peopleNo {
  margin-left: 10px;
  font-size: 12px;
}

/* Media Query for Responsive Design */
@media (max-width: 768px) {
  .job__card {
    width: 100%;
    height: 200px;
  }

  .card__content {
    padding: 8px; /* Adjusted padding */
  }

  .logo-container {
    width: 40px !important;
    height: 40px !important;
  }

  .company__size_info .company__info__size__logo {
    width: 18px; /* Adjusted icon size */
    height: 18px;
  }

  .company__size_info .company__info__size__peopleNo {
    font-size: 11px; /* Adjusted font size */
  }
  .jobCard__payCap {
    display: none;
  }
}
