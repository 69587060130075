.job__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
}

.header {
  position: sticky;
  top: 0;
  background-color: #222736;
  padding: 20px;
  border-bottom: 0.1px solid lightgray;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  z-index: 999;
}

.title {
  display: flex;
  align-items: center;
}

.title h1:hover {
  color: #007bff;
}

.title h1 {
  margin: 0;
  font-weight: normal;
  font-size: large;
  font-size: medium;
  margin-right: 20px;
  color: #ffffff;
  cursor: pointer;
}

.menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.menu li {
  display: inline;
  margin-right: 20px;
  color: #ffffff;
}

.menu li:hover {
  color: #007bff;
}

.menu li:last-child {
  margin-right: 0;
}

.menu a {
  text-decoration: none;
  font-weight: normal;
  transition: color 0.3s ease;
}

.menu a:hover {
  color: #007bff;
}

.menu {
  cursor: pointer;
  color: #252936;
}

/* empty list message */
.empty-job-list-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Set height to fill the viewport */
}

.empty-job-list-message p {
  font-size: 24px;
  text-align: center;
  color: #333;
  font-weight: bold;
}

/* specialization */
.specializations {
  margin-top: 30px;
  margin-bottom: 20px;
}

.specializations-icons {
  display: flex;
  justify-content: space-between;
  /* Evenly space the icons */
}

.specialization {
  text-align: center;
  font-size: 12px;
}

.icon {
  font-size: 18px;
  /* Adjust the size of the icon */
}

.no-jobs-found {
  text-align: center;
  font-size: 1.2em;
  color: #888;
  margin-top: 20px;
}

/* footer */
.footer {
  margin-top: 30px;
  background-color: #222736;
  padding: 50px 0;
  display: flex;
  color: #fff;
  margin-bottom: 20px;
}

.footer-section {
  width: 20%;
  float: left;
  padding: 0 20px;
}

.footer-title {
  color: #fff;
}

.footer-section h3 {
  font-size: 18px;
  margin-bottom: 15px;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 10px;
  color: #fff;
}

.footer-section ul li a {
  text-decoration: none;
  color: #fff;
}

.footer-section ul li a:hover {
  text-decoration: underline;
}

.footer p {
  margin-top: 30px;
  font-size: 14px;
  line-height: 1.5;
}

.footer ul {
  padding: 0;
  list-style: none;
}

.footer ul li {
  margin-bottom: 10px;
}

.footer ul li a {
  color: #2c1f1f;
  text-decoration: none;
}

.footer ul li a:hover {
  text-decoration: underline;
}

/* Privacy policy */
.privacy-policy {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
}

.privacy-policy h1 {
  font-size: 2.5em;
  color: #0073e6;
  text-align: center;
  margin-bottom: 20px;
}

.privacy-policy p {
  font-size: 1em;
  margin: 10px 0;
}

.privacy-policy h2 {
  font-size: 1.75em;
  color: #0073e6;
  margin-top: 30px;
}

.privacy-policy ul {
  list-style-type: disc;
  padding-left: 20px;
  margin: 10px 0;
}

.privacy-policy ul li {
  margin: 5px 0;
}

.privacy-policy a {
  color: #0073e6;
  text-decoration: none;
}

.privacy-policy a:hover {
  text-decoration: underline;
}

.privacy-policy .contact-info {
  margin-top: 30px;
  padding: 15px;
  background-color: #f9f9f9;
  border-left: 4px solid #0073e6;
}

.privacy-policy .contact-info p {
  margin: 5px 0;
}

@media (max-width: 768px) {
  .job-card {
    width: 5%;
  }

  .logo-container {
    width: 40px;
    height: 40px;
  }

  .company-info {
    font-size: 14px;
  }

  .company-logo img {
    width: 80px;
    height: auto;
    border-radius: 10px;
  }

  .specializations {
    display: none;
  }

  .footer-section {
    width: 100%;
    padding: 0 10px;
  }

  .privacy-policy {
    max-width: 95%;
    padding: 10px;
  }

  .privacy-policy h1 {
    font-size: 2em;
  }

  .privacy-policy h2 {
    font-size: 1.5em;
  }
  .job__container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header {
    flex-direction: column;
    justify-content: space-evenly;
    padding: 10px;
  }

  .title {
    margin-bottom: 10px;
  }

  .title h1 {
    font-size: 1.1rem;
  }

  .menu {
    width: 100%;
    display: none;
  }

  .menu ul {
    flex-direction: column;
    gap: 5px;
  }

  .menu li {
    margin-right: 0;
  }

  .footer {
    flex-direction: column;
    padding: 20px; /* Adjust padding for mobile */
  }

  .footer-section {
    width: 100%; /* Take full width on mobile */
    margin-bottom: 20px; /* Space between sections */
  }
}
